<template>
  <div v-if="pagesInfo.total / pagesInfo.per_page > 1" class="pagination">
    <el-pagination layout="pager" :current-page="pagesInfo.current_page" :total="pagesInfo.total" @current-change="pageChange" />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pagesInfo: {
      type: Object,
      default: null
    },
  },
  methods:{
    pageChange(val){
      if(val === 1 && this.$route.query.page) {
        let query = Object.assign({}, this.$route.query);
        delete query.page;
        this.$router.replace({ query });
      } else if(val !== 1) {
        const query = { ...this.$route.query, page: val }
        this.$router.push({ query })
      }
      setTimeout(() => {
        this.$emit('changePage')
      }, 0);
      
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.pagination {
  display: flex;
  justify-content: center;
  padding: 8rem 0;

  .el-pager {
    li {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 3.8rem;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 50%;
      margin-right: 1rem;
      transition: 0.3s ease-out;

      &.active,
      &:hover {
        background: $basicBlack;
        color: $basicWhite;
      }
    }
  }
}
</style>
