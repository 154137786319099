<template>
  <div class="users">
    <div class="container">
      <div v-if="usersStatistic" class="users__header">
        <div class="users__statistics">
          <div class="users__statistics-item">
            <div class="users__statistics-name">Всего пользователей:</div>
            <div class="users__statistics-value">{{ usersStatistic.count }}</div>
          </div>
          <div class="users__statistics-item">
            <div class="users__statistics-name">Пользователей онлайн:</div>
            <div class="users__statistics-value">{{ usersStatistic.online }}</div>
          </div>
          <div class="users__statistics-item">
            <div class="users__statistics-name">Проектов в системе:</div>
            <div class="users__statistics-value">{{ usersStatistic.projects }}</div>
          </div>
        </div>
        <div class="users__add">
          <ButtonElement
            text="Добавить пользователя"
            fill="empty"
            @click="newUser"
          />
        </div>
      </div>
      <div v-if="users" class="users__table">
        <el-table :data="users.items" style="width: 100%">
          <el-table-column prop="name" label="Имя/логин" />
          <el-table-column label="Дата регистрации">
            <template #default="scope">
              <div :key="scope.row.id">{{ fixedDate(scope.row.created_at) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="role" label="Роль" />
          <el-table-column width="50">
            <template #default="scope">
              <el-dropdown placement="bottom-end" trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  <img src="../assets/icons/icon-more.svg" alt="">
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{name: 'edit', id: scope.row.id}">Редактировать</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination
        v-if="users && users.paginate.total > 1"
        :pagesInfo="users.paginate"
        @changePage="getUsers($route.query)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Pagination from '../components/elements/Pagination'
import ButtonElement from '../components/elements/ButtonElement'
import { DateParser } from '@/mixin/date_parser'

export default {
  name: 'Users',
  mixins: [DateParser],
  components: {
    Pagination,
    ButtonElement,
  },
  data() {
    return {}
  },
  mounted() {
    this.getUsers(this.$route.query)
    this.getUsersStatistic()
  },
  computed: {
    users() {
      return this.$store.state.Users.users
    },
    usersStatistic() {
      return this.$store.state.Users.usersStatistic
    }
  },
  methods: {
    ...mapActions('Users', [
        'getUsers', 'getUsersStatistic'
    ]),
    handleCommand(command) {
      if (command.name === 'edit') {
        this.$router.push(`/users/${command.id}`)
      }
    },
    newUser() {
      this.$store.commit('Dialog/setData', {
        label: 'activeDialog',
        data: 'User'
      })
      this.$store.commit('Dialog/setData', {
        label: 'titleDialog',
        data: 'Добавить пользователя'
      })
      this.$store.commit('Dialog/setData', {
        label: 'dialogVisible',
        data: true
      })
    },
  }
}
</script>

<style lang="scss">
.users {
  padding-top: 8rem;

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 8rem;
  }

  &__statistics {
    display: flex;

    &-item {
      margin-right: 10rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-name {
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }

    &-value {
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4.9rem;
    }
  }

  &__table {
    .el-dropdown {
      width: 100%;

      &-link {
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
</style>
